import React, { useEffect, useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ROUTES } from "src/stores/RouterStore";
import {
  CommonStore,
  CustomerStore,
  PaymentStore,
  RouterStore,
} from "src/stores";
import api, { ICardDTO, IOrderDTO } from "../../services/api";
import { StripeElements } from "@stripe/stripe-js";
import { useElements } from "@stripe/react-stripe-js";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { groupUsedCredits } from "src/util/groupCredits";
import { PaymentMethodStore } from "../../stores";
import {
  ICalculatedOrderDTO,
  ICalculatedScheduleAndPayOrderDTO,
  IScheduleAndPayOrderDTO,
  isScheduleAndPayOrder,
} from "../../services/api/orders";
import * as Sentry from "@sentry/react";
import { scheduleAndPaySuccessRoute } from "../SuccessPages/ScheduleAndPaySuccessPage";
import { IUsedCreditRow } from "src/services/api/credits";
import { invoiceSentSuccessRoute } from "../SuccessPages/InvoiceSentSuccessPage";
import {
  CustomerPaymentMethod,
  PaymentMethodPickerVm,
} from "../../components/PaymentMethods/PaymentMethodPickerVm";
import { assertNever, raise } from "@sizdevteam1/funjoiner-uikit";
import { CustomerOrderEmailVm } from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/CustomerOrderEmailVm";
import { OrderPlacerVm } from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/OrderPlacerVm";

export class CheckoutAndPaymentPageVM {
  private disposers: (() => void)[] = [];
  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
  };
  constructor(
    private paymentStore: PaymentStore,
    paymentMethodStore: PaymentMethodStore,
    private routerStore: RouterStore,
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    private elements: StripeElements | null
  ) {
    makeObservable(this);
    if (!this.paymentStore.incompleteOrder) {
      this.routerStore.navigate(ROUTES.DASHBOARD, {
        replace: true,
      });
    }

    this.selectedPaymentPlanId = this.routerStore.searchParams.payment_plan_id;

    this.paymentMethodPickerVm = new PaymentMethodPickerVm(
      {
        isInvoiceEnabled: computed(
          () =>
            "sign_up" in this.order &&
            this.selectedConfirmedAvailablePaymentPlan == null
        ),
        applePay: {
          payment: computed(() => {
            let finalPrice: number;
            if (this.selectedConfirmedAvailablePaymentPlan) {
              const installments = this.order.payment_plan?.installments;
              finalPrice = installments?.[0]?.amount ?? 0;
            } else {
              finalPrice = this.order.final_price;
            }

            return {
              final_price: finalPrice,
              description: this.order.description,
            };
          }),
          isImmediateChargeRequired: true,
        },
      },
      elements,
      paymentStore,
      paymentMethodStore
    );

    this._orderPlacer = new OrderPlacerVm<
      IOrderDTO | IScheduleAndPayOrderDTO,
      CustomerPaymentMethod
    >(
      (customerEmail, paymentMethod) => {
        const order = this.paymentStore.incompleteOrderPayload;
        if (order == null) throw new Error("Order shouldn't be null on place");

        const common = {
          new_customer_email: customerEmail,
          payment_type: paymentMethod.orderPaymentType,
        };
        if (order.type === "schedule_and_pay") {
          return api.orders.placeScheduleAndPayOrder({
            ...common,
            ...order.payload,
          });
        } else if (order.type === "order") {
          return api.orders.place({
            ...common,
            ...order.payload,
          });
        } else {
          assertNever(order);
        }
      },
      computed(() => this.paymentStore.incompleteOrder),
      this.emailVm,
      this.paymentMethodPickerVm,
      {
        attachPaymentToInstallment: api.orders.payInstallment, // todo
        getOrderById: api.orders.getOrderById,
        confirmPayment: (paymentMethodId, stripeClientSecret) =>
          this.paymentStore.confirmPayment(
            stripeClientSecret,
            paymentMethodId,
            true
          ),
      }
    );

    this.disposers.push(this.paymentMethodPickerVm.dispose);
  }

  @observable
  paymentMethodPickerVm: PaymentMethodPickerVm;

  @computed
  get step() {
    return this.routerStore.searchParams["step"] === "payment"
      ? "payment"
      : this.routerStore.searchParams["step"] === "payment_plans"
      ? "payment_plans"
      : "checkout";
  }

  //PaymentPlans

  @computed get availablePaymentPlans() {
    return this.paymentStore.availablePaymentPlans;
  }

  @action.bound selectPaymentPlan(id?: string) {
    this.selectedPaymentPlanId = id;
  }

  @observable selectedPaymentPlanId?: string;

  @computed get selectedConfirmedAvailablePaymentPlan() {
    if (!this.selectedPaymentPlanId) return undefined;
    return this.availablePaymentPlans.find(
      (p) => p.payment_plan.id === this.selectedPaymentPlanId
    );
  }

  @computed get attachedPaymentPlan() {
    return this.order.payment_plan;
  }

  @action.bound navigateToPaymentPlans() {
    this.routerStore.setSearchParam("step", "payment_plans", true);
  }

  @action.bound async confirmPaymentPlanOption() {
    this.selectedPaymentPlanId
      ? await this.paymentStore.attachPaymentPlan(this.selectedPaymentPlanId)
      : await this.paymentStore.detachPaymentPlan();

    const paymentPlanId =
      this.selectedConfirmedAvailablePaymentPlan?.payment_plan.id;

    this.routerStore.navigate(
      ROUTES.SCHEDULE_CHECKOUT,
      paymentPlanId != null
        ? {
            searchParams: {
              payment_plan_id: paymentPlanId,
            },
            replace: true,
          }
        : {}
    );
  }

  //Payment Plans End

  @observable
  agreeToPolicy: boolean = false;

  @observable
  isPromoInputFocused: boolean = false;

  @computed
  get creditsToUse(): IUsedCreditRow[] {
    return "credits_used" in this.order && this.order["credits_used"]
      ? groupUsedCredits(this.order["credits_used"], this.customerStore.credits)
      : [];
  }

  get order(): ICalculatedOrderDTO | ICalculatedScheduleAndPayOrderDTO {
    return this.paymentStore.incompleteOrder ?? raise("Order is null");
  }

  @computed get orderOwnFeesTotalAmount() {
    return this.order.own_fees.reduce((acc, f) => acc + f.amount, 0);
  }

  @action
  proceedToPayment = () => {
    this.routerStore.setSearchParam("step", "payment");
  };

  @observable
  processingPayment: {
    paymentMethod: ICardDTO;
    amount: number;
  } | null = null;

  emailVm = new CustomerOrderEmailVm(
    computed(() => this.customerStore.customer.email ?? undefined)
  );
  _orderPlacer;
  @action
  onPayClicked = async () => {
    if (!this.order || !this.elements) throw new Error("Order is null");

    try {
      const { order, type, card } = await this._orderPlacer.placeOrder({
        onOrderPollingStarted: (order, card) => {
          this.processingPayment = {
            paymentMethod: card,
            amount:
              isScheduleAndPayOrder(order) && order.payment_plan
                ? order.payment_plan.installments[0].amount
                : order.final_price,
          };
        },
      });
      if (type === "stripe_invoice") {
        this.routerStore.navigateToRoute(
          invoiceSentSuccessRoute.build({
            state: { order: order as IScheduleAndPayOrderDTO },
          })
        );
      }
      if (card != null) {
        this.paymentStore.saveCompletedOrder(order, card);
      }

      runInAction(() => {
        this.paymentStore.setIncompleteOrder(null);
        this.processingPayment = null;

        this.customerStore.loadPromocodes();
        if (isScheduleAndPayOrder(order)) {
          this.routerStore.navigateToRoute(
            scheduleAndPaySuccessRoute.build({
              state: {
                bookingResult: {
                  attendanceIds: order.sign_up.created_attendance_ids,
                },
              },
            })
          );
        } else {
          this.routerStore.navigate(ROUTES.PAYMENT_SUCCESS);
        }
      });

      //Todo: Find more elegant way to load credits
      this.customerStore.loadCredits().then();
    } catch (e) {
      runInAction(() => {
        this.processingPayment = null;
      });
      console.error(e);
      Sentry.captureException(e);
      notificator.error("Payment failed!", e);
    }
  };

  @computed
  get policies() {
    return this.commonStore.companyProfile?.documents.terms_of_service;
  }
}

const ctx = React.createContext<CheckoutAndPaymentPageVM | null>(null);

export const CheckoutAndPaymentPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const {
    paymentStore,
    paymentMethodStore,
    routerStore,
    customerStore,
    commonStore,
  } = useStores();
  const elements = useElements();
  const vm = useMemo(
    () =>
      new CheckoutAndPaymentPageVM(
        paymentStore,
        paymentMethodStore,
        routerStore,
        customerStore,
        commonStore,
        elements
      ),
    [
      paymentStore,
      paymentMethodStore,
      routerStore,
      customerStore,
      commonStore,
      elements,
    ]
  );

  useEffect(() => () => vm.dispose());
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useCheckoutAndPaymentPageVM = () => useVM(ctx);
