import React from "react";
import {
  CustomDocumentPageVMProvider,
  useCustomDocumentPageVM,
} from "./CustomDocumentPageVM";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import {
  Avatar,
  Button,
  FileUploader,
  MarkdownText,
  Splash,
} from "@sizdevteam1/funjoiner-uikit";
import PageHeader from "src/components/PageHeader";
import classNames from "classnames";
import storageFileResolver from "../../../services/storageFileResolver";
import { defineRoute } from "../../../util/typedRouting";

interface IProps {}

export const customDocumentPageRoute = defineRoute<{
  pathParams: {
    id: string;
  };
}>({
  path: "/documents/:id",
  build: (path, { id }) => {
    return path.replace(":id", id);
  },
});
const CustomDocumentPage: React.FC<IProps> = (props) => (
  <CustomDocumentPageVMProvider>
    <CustomDocumentPageImpl {...props} />
  </CustomDocumentPageVMProvider>
);
const CustomDocumentPageImpl: React.FC<IProps> = observer((props: IProps) => {
  const vm = useCustomDocumentPageVM();
  return (
    <PageRoot className="gap-4">
      {vm.isLoading ? (
        <Splash pathToAnimation={"/splash.json"} />
      ) : (
        <>
          <PageHeader
            showBackLink={true}
            className="typography-h2 flex flex-row gap-2 text-text-color"
          >
            <i className="icon doc-blank-icon" />
            {vm.document!.name}
          </PageHeader>

          <StudentTile />
          <ResubmissionReason />
          <Card className="typography-main space-y-4 text-text-color">
            <MarkdownText className={"break-words "}>
              {vm.document?.description ?? ""}
            </MarkdownText>
            <TemplateAttachments />
            <FileUploader
              allow={["application/pdf", "image/*", "video/*"]}
              className="w-auto"
              uploadFile={vm.uploadFile}
              onChanged={vm.setUploads}
            />
          </Card>
          <Button
            kind="action"
            size="big"
            className="mt-auto mb-3"
            disabled={!vm.isSubmitEnabled}
            onClick={vm.submit}
            autoLoading={true}
          >
            Submit
          </Button>
        </>
      )}
    </PageRoot>
  );
});

const TemplateAttachments: React.FC = observer(() => {
  const attachments = useCustomDocumentPageVM().document?.template_attachments;
  if (attachments == null) {
    return null;
  }

  const cutLongFileName = (fileName: string) => {
    if (fileName.length > 24) return fileName.substring(0, 28) + "...";
    else return fileName;
  };

  return (
    <div className="flex flex-wrap gap-2">
      {attachments.map((f) => (
        <button
          key={f.key}
          className="typography-button cursor-pointer rounded-[6px] border-none bg-highlight-color px-[10px] py-[6px] text-main-color"
          onClick={async (e) => {
            e.preventDefault();
            await storageFileResolver
              .getSignedLink(f.download_url, true)
              .then((url: string) => {
                window.open(url, "_self");
              });
          }}
        >
          {cutLongFileName(f.public_name)}
        </button>
      ))}
    </div>
  );
});

const ResubmissionReason: React.FC = observer(() => {
  const reason = useCustomDocumentPageVM().document!.resubmission_reason;
  if (reason == null) return null;
  return (
    <Card className="typography-main space-y-4 border-[1.7px] border-solid border-main-color">
      <span className="typography-label text-placeholder-color">
        Reason for Resubmission:
      </span>
      <br></br>
      <span className="typography-main text-text-color">{reason}</span>
    </Card>
  );
});

const StudentTile: React.FC = observer(() => {
  const student = useCustomDocumentPageVM().student!;
  return (
    <div className="flex flex-row items-center gap-2">
      <Avatar person={student} avatarSize={"32px"} />
      <span className="typography-h3 text-text-color">{student.full_name}</span>
    </div>
  );
});

const Card: React.FC<{ className?: string; children?: React.ReactNode }> =
  observer(({ className, children }) => {
    return (
      <div
        className={classNames(
          "rounded-[10px] bg-on-main-color p-4 shadow-big",
          className
        )}
      >
        {children}
      </div>
    );
  });

export default CustomDocumentPage;
