import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import React, { useEffect, useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import FunboxStore from "src/stores/FunboxStore";
import { AuthStore, RouterStore } from "src/stores";
import { ROUTES } from "src/stores/RouterStore";
import { AvailabilityVM } from "./components/AvailabilityVM";
import api from "src/services/api";
import {
  ScheduleSet,
  SessionInProgram,
  TAvailableProgram,
} from "src/services/api/availability";
import dayjs from "dayjs";
import { ISOString, raise } from "@sizdevteam1/funjoiner-uikit";
import navigateToSchedule from "src/util/navigateToSchedule";

export class AvailabilityPageVM {
  private disposers: (() => void)[] = [];

  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
    this.availabilityVM.dispose();
  };
  @observable public availabilityVM: AvailabilityVM;
  constructor(
    private funboxStore: FunboxStore,
    private routerStore: RouterStore,
    private authStore: AuthStore
  ) {
    makeObservable(this);
    this.availabilityVM = new AvailabilityVM(
      this.routerStore,
      computed(
        () => this.funboxStore.selectedFunbox ?? raise("funbox is null")
      ),
      computed(
        () => this.funboxStore.selectedLocation?.id ?? raise("Location is null")
      ),
      "availability_page"
    );
    if (routerStore.searchParams.open_schedule_set_id) {
      this.loadExpandedScheduleSetById(
        this.routerStore.searchParams.open_schedule_set_id
      );
    }
  }

  @action.bound navigateToScheduleProgram(program: TAvailableProgram) {
    navigateToSchedule(this.routerStore, this.authStore.loggedIn, {
      type: "program",
      schedule_set_id: program.schedule_set_id,
      program_id: program.id,
      selectedScheduleMonth: dayjs(program.start_date)
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
  }

  @action.bound navigateToScheduleSession({
    id,
    date,
  }: {
    id: string;
    date: ISOString;
  }) {
    navigateToSchedule(this.routerStore, this.authStore.loggedIn, {
      type: "session",
      session_id: id,
      session_date: date,
      selectedScheduleMonth: dayjs(date).startOf("month").format("YYYY-MM-DD"),
    });
  }

  @action.bound navigateToJoinWaitlist(program: TAvailableProgram) {
    navigateToSchedule(this.routerStore, this.authStore.loggedIn, {
      type: "join_waitlist",
      program_id: program.id,
      schedule_set_id: program.schedule_set_id,
      selectedScheduleMonth: dayjs(program.start_date)
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
  }
  @action.bound navigateToApply(program: TAvailableProgram) {
    navigateToSchedule(this.routerStore, this.authStore.loggedIn, {
      type: "apply",
      program_id: program.id,
      schedule_set_id: program.schedule_set_id,
      selectedScheduleMonth: dayjs(program.start_date)
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
  }

  @action.bound private async loadExpandedScheduleSetById(set_id: string) {
    const set = await api.availability.getScheduleSetById(set_id);
    runInAction(() => {
      this.setToInitiallyOpenInDescriptionModal = set;
      this.expandedScheduleSetId = set.id;
      this.routerStore.setSearchParam("funbox", undefined, true);
      this.routerStore.setSearchParam("location", undefined, true);
      this.routerStore.setSearchParam("open_schedule_set_id", undefined, true);
      this.availabilityVM.setCustomMonthDayJS(dayjs(set.start_date));
      if (set.funbox_mode === "SESSIONS") {
        this.firstNearbySession = set.programs
          .flatMap((p) => p.sessions)
          .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
          .find((s) => dayjs(s.date).endOf("day").isAfter(dayjs()));
      }
    });
  }

  @observable
  setToInitiallyOpenInDescriptionModal?: ScheduleSet;

  @observable firstNearbySession?: SessionInProgram;

  @computed get availability() {
    return this.availabilityVM.availability;
  }

  @action.bound
  async previousMonth() {
    this.availabilityVM.previousMonth();
  }

  @action.bound
  async nextMonth() {
    this.availabilityVM.nextMonth();
  }

  @observable
  expandedScheduleSetId: string | null = null;

  @action.bound
  toggleScheduleSet(id: string | null) {
    if (id === this.expandedScheduleSetId) {
      this.expandedScheduleSetId = null;
      return;
    } else {
      this.expandedScheduleSetId = id;
    }
  }

  @observable isSelectLocationModalOpen = false;

  @computed get isAtLeastOneOtherFunboxAvailable() {
    return (
      this.funboxStore.funboxes.filter((f) => f.id !== this.selectedFunbox?.id)
        .length > 0
    );
  }

  @action.bound toSchedule() {
    this.routerStore.navigate(ROUTES.SCHEDULE);
  }

  @action.bound toCreditsAndPackages() {
    this.routerStore.navigate(ROUTES.FLEXIBLE_PAYMENTS);
  }

  @computed
  get isSomethingAvailable() {
    return this.availabilityVM.isSomethingAvailable;
  }

  get isBuyCreditsAndSaveEnabled() {
    return this.funboxStore.isBuyCreditsAndSaveEnabled;
  }

  @computed
  get selectedLocation() {
    return this.funboxStore.selectedLocation;
  }

  @computed get selectedFunbox() {
    return this.funboxStore.selectedFunbox;
  }
}

const ctx = React.createContext<AvailabilityPageVM | null>(null);

export const AvailabilityPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { routerStore, funboxStore, authStore } = useStores();

  const vm = useMemo(
    () => new AvailabilityPageVM(funboxStore, routerStore, authStore),
    [funboxStore, routerStore, authStore]
  );
  useEffect(
    () => () => {
      vm.dispose();
    },
    [vm]
  );
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useAvailabilityPageVM = () => useVM(ctx);
