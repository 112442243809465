import React, { useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import { action, computed, makeObservable, observable } from "mobx";
import { CustomerStore, DocumentsStore, RouterStore } from "src/stores";
import { ROUTES } from "../../stores/RouterStore";
import { matchPath } from "react-router-dom";
import notificator from "../../services/systemNotifications/notificationCenterService";
import { IStudentDTO, TDocumentDTO } from "src/services/api";
import { computedFn } from "mobx-utils";
import { isSignedByCustomer } from "src/util/isSignedByCustomer";
import storageFileResolver from "../../services/storageFileResolver";
import { delay } from "@sizdevteam1/funjoiner-uikit";
import { documentsPageRoute } from "./DocumentsPage";

export class DocumentsPageVM {
  constructor(
    private documentsStore: DocumentsStore,
    private customerStore: CustomerStore,
    private routerStore: RouterStore
  ) {
    makeObservable(this);
    this._init();
  }

  @observable
  loading = true;

  @computed
  private get _selectedStudentId() {
    const studentId = documentsPageRoute.getParams(this.routerStore)
      .searchParams.selectedStudentId;

    if (studentId == null) {
      return undefined;
    }

    return Number(studentId);
  }

  @computed get selectedStudent() {
    return this.students.find((s) => s.id === this._selectedStudentId);
  }

  @action.bound
  setSelectedStudentId(id: number) {
    this.routerStore.setSearchParam("selectedStudentId", id, true);
  }

  @computed
  get students(): IStudentDTO[] {
    return this.customerStore.studentsWithCustomerAsParticipant.filter(
      (e) => this._getStudentDocuments(e.id).length > 0
    );
  }

  @computed
  get hasDocuments() {
    return this._selectedStudentDocuments.length > 0;
  }

  @computed
  get signedAndNotExpiredDocuments(): TDocumentDTO[] {
    if (!this.selectedStudent) {
      return [];
    }
    return this._getSignedAndNotExpiredDocuments(this.selectedStudent.id);
  }

  @computed
  get unsignedDocuments() {
    return this._selectedStudentDocuments.filter((d) => !isSignedByCustomer(d));
  }

  @computed
  get expiredDocuments() {
    return this._selectedStudentDocuments.filter(({ expired }) => expired);
  }

  @computed
  get averageCompleteness(): number | undefined {
    if (this.students.length === 0) return undefined;
    return Math.floor(
      this.students.reduce((avg, e) => avg + this.completenessFor(e.id), 0) /
        this.students.length
    );
  }

  completenessFor = computedFn((studentId: number): number => {
    const unexpiredDocumentsLength = this._getStudentDocuments(
      studentId
    ).filter(({ expired }) => !expired).length;

    let ratio: number;

    if (unexpiredDocumentsLength === 0) {
      ratio = 1;
    } else {
      const signedLength =
        this._getSignedAndNotExpiredDocuments(studentId).length;
      ratio = signedLength / unexpiredDocumentsLength;
    }

    return Math.floor(ratio * 100);
  });

  @action
  async signDocument(documentId: string) {
    await this.documentsStore.signDocument(documentId, (document) => {
      if (document.type === "custom_document") {
        return documentsPageRoute.build({
          searchParams: {
            selectedStudentId: this._selectedStudentId?.toString(),
          },
        }).absoluteUrl;
      }
      return `${
        window.location.origin
      }${ROUTES.DOCUMENT_SIGN_SUCCESS_CALLBACK.replace(":id", document.id)}`;
    });
  }

  @action
  async openSignedDocument(documentId: string) {
    const doc = this.documentsStore.documents.find(
      ({ id }) => id === documentId
    );
    if (doc == null || !doc.download_url) return;
    const url = await storageFileResolver.getSignedLink(doc.download_url);
    window.open(url, "_blank");
  }

  private async _init() {
    const match = matchPath(this.routerStore.currentPath, {
      path: ROUTES.DOCUMENTS,
    });
    if (match == null) {
      return;
    }
    await this._initDocuments();
    const firstStudent = this.students[0];
    if (firstStudent != null && this.selectedStudent == null) {
      this.setSelectedStudentId(firstStudent.id);
    }
  }

  private _initDocuments() {
    const match = matchPath<{ id: string }>(this.routerStore.currentPath, {
      path: ROUTES.DOCUMENT_SIGN_SUCCESS_CALLBACK,
      exact: true,
      strict: false,
    });

    if (match == null || !match.params.id) {
      return this.documentsStore
        .loadDocuments()
        .finally(() => (this.loading = false));
    }

    const documentId = match.params.id;
    this.routerStore.navigate(ROUTES.DOCUMENTS, { replace: true });
    const to = delay(20000);
    to.then(() => (expired = true));

    let expired = false;
    const promise = (): Promise<void> =>
      this.documentsStore.loadDocuments().then(() => {
        const doc = this.documentsStore.documents.find(
          ({ id }) => id === documentId
        );
        if (doc?.signed) {
          to.cancel();
          return;
        }
        if (expired) {
          notificator.error(
            "Error",
            "Failed to get signature confirmation. Try to refresh the page"
          );
          return;
        }
        return delay(2000).then((_) => promise());
      });

    return promise().finally(() => (this.loading = false));
  }

  @computed
  private get _selectedStudentDocuments() {
    if (!this.selectedStudent) return [];
    return this._getStudentDocuments(this.selectedStudent.id);
  }

  private _getSignedAndNotExpiredDocuments = computedFn((studentId: number) => {
    return this._getStudentDocuments(studentId).filter(
      (d) => isSignedByCustomer(d) && !d.expired
    );
  });

  private _getStudentDocuments = (studentId: number | undefined) => {
    const docs = this.documentsStore.documents;
    return docs.filter((e) => e.student_id === studentId);
  };

  dispose = () => {};
}

const ctx = React.createContext<DocumentsPageVM | null>(null);

export const DocumentsPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { documentsStore, customerStore, routerStore } = useStores();

  const vm = useMemo(
    () => new DocumentsPageVM(documentsStore, customerStore, routerStore),
    [documentsStore, customerStore, routerStore]
  );

  React.useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useDocumentsPageVM = () => useVM(ctx);
