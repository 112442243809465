import React, { useMemo } from "react";
import dayjs from "dayjs";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { CustomerStore, RouterStore } from "src/stores";
import { ICreateStudentDTO, TPersonalPronoun } from "src/services/api";
import { IOption } from "@sizdevteam1/funjoiner-uikit/components/Select/interfaces";
import { getNameError } from "src/util/validators";
import { observer } from "mobx-react-lite";
import notificator from "../../services/systemNotifications/notificationCenterService";
import { CommonStore } from "../../stores";
import { ALL_PERSONAL_PRONOUNS } from "../../services/api/students";
import { useParams } from "react-router-dom";
import UpdatePhoto from "../../models/UpdatePhoto";
import GenderPickerVm from "../../models/GenderPickerVm";

type TInputs =
  | "first_name"
  | "last_name"
  | "medical_considerations"
  | "allergies"
  | "notes";
type TErrors = "first_name_error" | "last_name_error" | "date_error";

export class ParticipantCreateOrEditPageVM {
  public updatePhoto: UpdatePhoto;
  constructor(
    public participantId: number | null,
    private commonStore: CommonStore,
    private customerStore: CustomerStore,
    private routerStore: RouterStore
  ) {
    makeObservable(this);
    this._personal_pronoun = this.participantOnEdit?.personal_pronoun ?? "none";
    this.gender = new GenderPickerVm(
      this.participantOnEdit?.gender ?? undefined,
      this.commonStore.publicSettings.baseline_questions.for_participants.gender
    );
    this.updatePhoto = new UpdatePhoto(
      "COMPANY_PUBLIC",
      this.participantOnEdit?.thumbnail_file
    );
  }

  get isEdit() {
    return this.participantId !== null;
  }

  @observable createdParticipantsCount = 0;

  @observable
  first_name = this.participantOnEdit?.first_name ?? "";

  @observable
  first_name_error: string | null = null;

  @observable
  last_name = this.participantOnEdit?.last_name ?? "";

  @observable
  last_name_error: string | null = null;

  @computed
  get baselineQuestionsForParticipants() {
    return this.commonStore.publicSettings.baseline_questions.for_participants;
  }

  @observable
  private _personal_pronoun: TPersonalPronoun;
  @computed
  get pronoun() {
    return this._personal_pronoun;
  }
  @action.bound
  setPronoun(value: TPersonalPronoun) {
    this.personalPronounError = null;
    this._personal_pronoun = value;
  }

  @observable
  personalPronounError: string | null = null;
  @computed
  get selectedPronounOption() {
    return this.pronounToOption(this._personal_pronoun);
  }

  gender: GenderPickerVm;

  @computed
  get availablePronounOptions() {
    return this.availablePronouns.map(this.pronounToOption);
  }

  pronounToOption = (pronoun: TPersonalPronoun): IOption<TPersonalPronoun> => {
    const names: Record<TPersonalPronoun, string> = {
      none: "None",
      she: "She/Her",
      he: "He/Him",
      they: "They/Them",
      "non-binary": "Non-Binary",
    };

    return {
      id: pronoun,
      name: names[pronoun],
    };
  };

  @computed
  get availablePronouns(): TPersonalPronoun[] {
    const available: TPersonalPronoun[] = [...ALL_PERSONAL_PRONOUNS];
    if (this.baselineQuestionsForParticipants.pronoun.status !== "optional") {
      available.splice(available.indexOf("none"), 1);
    }

    return available;
  }

  @observable
  date?: string = this.participantOnEdit?.birth_date;

  @observable
  date_error: string | null = null;

  @observable
  medical_considerations = this.participantOnEdit?.medical_considerations ?? "";

  @observable
  allergies = this.participantOnEdit?.allergies ?? "";

  @observable
  notes = this.participantOnEdit?.customer_notes ?? "";

  @computed
  get defaultDate() {
    return this.date
      ? dayjs(this.date).toISOString()
      : dayjs().subtract(10, "year").toISOString();
  }

  @computed
  get participantOnEdit() {
    return this.participantId
      ? this.customerStore.studentsWithCustomerAsParticipant.find(
          (student) => student.id === this.participantId
        )
      : undefined;
  }

  @computed get participantOnEditIsCustomer() {
    return this.participantOnEdit?.type === "CUSTOMER_AS_PARTICIPANT";
  }

  @computed
  get isValid() {
    return (
      !this.first_name_error &&
      !this.last_name_error &&
      !this.date_error &&
      !this.personalPronounError &&
      !this.gender.field.error
    );
  }

  @action
  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name as TInputs;
    const errorName = (e.currentTarget.name + "_error") as TErrors;
    this[name] = e.currentTarget.value;
    this[errorName] = null;
  };

  @action
  handleDateChange = (date?: string | string[]) => {
    this.date = Array.isArray(date) ? date[0] : date;
    this.date_error = null;
  };

  @action
  validateFields = () => {
    this.first_name_error = getNameError(this.first_name, "First name");
    this.last_name_error = getNameError(this.last_name, "Last name");
    this.date_error = !this.date ? "Birth date is required" : null;
    this.personalPronounError =
      this.baselineQuestionsForParticipants.pronoun.status === "required" &&
      this._personal_pronoun === "none"
        ? "Pronoun is required"
        : null;
    this.gender.validate();
  };

  @action
  reset = () => {
    this.first_name = "";
    this.first_name_error = null;
    this.last_name = "";
    this.last_name_error = null;
    this._personal_pronoun = "none";
    this.updatePhoto.setNewImage(undefined);
    this.date = undefined;
    this.date_error = null;
    this.medical_considerations = "";
    this.allergies = "";
    this.notes = "";
  };

  @action
  private _submit = async () => {
    const birth_date = dayjs(this.date as string).format("YYYY-MM-DD");
    const dto: ICreateStudentDTO = {
      first_name: this.first_name.trimStart(),
      last_name: this.last_name.trimStart(),
      personal_pronoun: this._personal_pronoun,
      birth_date,
      allergies: this.allergies,
      medical_considerations: this.medical_considerations,
      customer_notes: this.notes,
      gender: this.gender.field.value,
    };

    if (this.updatePhoto.hasChanged) {
      dto.photo_key = this.updatePhoto.newFileKey;
    }

    if (this.participantId) {
      await this.customerStore.updateParticipant(this.participantId, dto);
    } else {
      await this.customerStore.createParticipant(dto);
      runInAction(() => {
        this.createdParticipantsCount++;
      });
    }
  };

  @action submit = async (onSuccess?: () => void) => {
    this.validateFields();
    if (!this.isValid) return;
    try {
      await this._submit();
      onSuccess?.();
    } catch (e) {
      notificator.error("Error", e);
    }
  };

  @action.bound returnToSourcePage() {
    this.routerStore.returnToSourcePage();
  }
}

const ctx = React.createContext<ParticipantCreateOrEditPageVM | null>(null);

export const ParticipantCreateOrEditPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = observer(({ children }) => {
  const { customerStore, commonStore, routerStore } = useStores();
  const { id } = useParams<{ id: string }>();
  const participantId = id ? Number(id) : null;

  const vm = useMemo(
    () =>
      new ParticipantCreateOrEditPageVM(
        participantId,
        commonStore,
        customerStore,
        routerStore
      ),
    [participantId, customerStore, commonStore, routerStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
});
export const useParticipantCreateOrEditPageVM = () => useVM(ctx);
