import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { IUpdateCustomerDTO } from "src/services/api";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { CustomerStore } from "src/stores";
import { getNameError, getPhoneError } from "src/util/validators";

export type Contact = {
  name: string;
  phone_number?: string;
  email?: string;
  address?: string;
};

class ContactsPageVM {
  EditContactVM: EditContactVM;
  constructor(private customerStore: CustomerStore) {
    makeObservable(this);
    this.EditContactVM = new EditContactVM(customerStore, this);
  }

  @computed get contacts() {
    return this.customerStore.customer.emergency_contacts;
  }

  @observable isContactsModalOpen = false;

  @action.bound openContactModal(contact?: Contact) {
    this.EditContactVM = new EditContactVM(this.customerStore, this, contact);
    this.isContactsModalOpen = true;
  }

  @action.bound closeContactModal() {
    this.EditContactVM = new EditContactVM(this.customerStore, this);
    this.isContactsModalOpen = false;
  }
}

class EditContactVM {
  constructor(
    private customerStore: CustomerStore,
    private contactsPageVM: ContactsPageVM,
    private contact?: Contact
  ) {
    makeObservable(this);
    if (contact) {
      this.name = contact.name;
      this.phone = contact.phone_number ?? "";
    }
  }

  @observable name = "";
  @observable nameError: string | null = null;
  @observable phone = "";
  @observable phoneError: string | null = null;

  @computed get customer() {
    return this.customerStore.customer;
  }

  @action.bound setName(v: string) {
    this.name = v;
    this.nameError = null;
  }

  @action.bound
  setPhone(value: string | undefined) {
    this.phone = value ?? "";
    this.phoneError = null;
  }

  @computed get contactOnEdit() {
    return this.contact;
  }

  @action private validate() {
    this.nameError = getNameError(this.name);
    this.phoneError = getPhoneError(this.phone);
    return !(this.nameError || this.phoneError);
  }

  @action.bound async deleteContact() {
    const contacts = this.customer.emergency_contacts.filter(
      (c) => c !== this.contactOnEdit
    );
    try {
      await this.customerStore.updateProfile({ emergency_contacts: contacts });
      this.contactsPageVM.closeContactModal();
    } catch (e) {
      notificator.error("Error", e);
    }
  }

  @action.bound async submit() {
    if (!this.validate()) return;
    const contact = {
      name: this.name,
      phone_number: this.phone,
    };

    const index = this.customer.emergency_contacts.findIndex(
      (s) => s.phone_number === this.contactOnEdit?.phone_number
    );

    const contacts = this.customer.emergency_contacts;
    if (index === -1) {
      contacts.push(contact);
    } else {
      contacts[index] = contact;
    }

    const dto: IUpdateCustomerDTO = {
      emergency_contacts: contacts,
    };
    try {
      await this.customerStore.updateProfile(dto);
      this.contactsPageVM.closeContactModal();
    } catch (e) {
      notificator.error("Error", e);
    }
  }
}

const ctx = React.createContext<ContactsPageVM | null>(null);

export const ContactsPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { customerStore } = useStores();

  const vm = useMemo(() => new ContactsPageVM(customerStore), [customerStore]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useContactsPageVM = () => useVM(ctx);
