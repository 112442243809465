import React, { useMemo, useEffect } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import useStores from "../../hooks/useStores";
import { useVM } from "@sizdevteam1/funjoiner-uikit";
import { CustomerStore, RouterStore } from "../../stores";
import api, {
  IncidentReportDTO,
  MedicationRecordDTO,
} from "../../services/api";
import { ROUTES } from "../../stores/RouterStore";
import { IPagination } from "../../services/api/common";
import { downloadFile } from "../../util/downloadFile";
import notificator from "../../services/systemNotifications/notificationCenterService";
import { isMedicationRecord } from "../../services/api/healthLog";

export class ParticipantsPageVM {
  private _disposers: (() => void)[] = [];
  constructor(
    private customerStore: CustomerStore,
    private routerStore: RouterStore
  ) {
    makeObservable(this);

    this.init();
  }

  @observable private healthLogPagination: IPagination<
    IncidentReportDTO | MedicationRecordDTO
  > = {
    total: 0,
    offset: 0,
    has_more: false,
    items: [],
  };
  @observable hasAnyStudentVisibleHealthLogRecords = false;

  @computed get healthLog() {
    return this.healthLogPagination.items;
  }
  @computed get hasMoreHealthLog() {
    return this.healthLogPagination.has_more;
  }

  @action toAddParticipant = () => {
    this.routerStore.navigate(ROUTES.ADD_PARTICIPANT);
  };
  @action toEditParticipant = (studentId: number) => () =>
    this.routerStore.navigate(ROUTES.EDIT_PARTICIPANT, {
      id: studentId,
    });

  @computed get selectedStudent() {
    return (
      this.customerStore.studentsWithCustomerAsParticipant.find(
        (s) => s.id === +this.routerStore.searchParams.selectedStudentId
      ) ?? this.customerStore.studentsWithCustomerAsParticipant.at(0)
    );
  }
  @computed get students() {
    return this.customerStore.studentsWithCustomerAsParticipant;
  }
  @action.bound selectStudent(student: { id: number }) {
    this.routerStore.setSearchParam("selectedStudentId", student.id.toString());
  }

  @observable isHealthLogLoading = false;
  @action.bound private async reloadHealthLoad() {
    if (!this.selectedStudent) return;
    this.isHealthLogLoading = true;
    try {
      const healthLog = await api.healthLog.getStudentHealthLog(
        this.selectedStudent.id,
        {
          limit: 25,
          offset: 0,
        }
      );
      runInAction(() => {
        this.healthLogPagination = healthLog;
      });
    } finally {
      runInAction(() => {
        this.isHealthLogLoading = false;
      });
    }
  }

  downloadHealthLogItem = async (
    item: IncidentReportDTO | MedicationRecordDTO
  ) => {
    try {
      await downloadFile({
        apiCall: () =>
          isMedicationRecord(item)
            ? api.healthLog.medicationRecordPdf(item.id)
            : api.healthLog.incidentReportPdf(item.id),
        fileName: `health-log_${item.id}.pdf`,
      });
    } catch (e) {
      notificator.error("Failed to download", e);
    }
  };
  @observable isLoadingNextPage = false;
  @action.bound async loadNextPage() {
    if (!this.selectedStudent) return;
    if (this.isLoadingNextPage) return;

    this.isLoadingNextPage = true;
    try {
      const result = await api.healthLog.getStudentHealthLog(
        this.selectedStudent.id,
        {
          limit: 25,
          offset: this.healthLogPagination.items.length,
        }
      );

      this.healthLogPagination = {
        offset: result.offset,
        total: result.total,
        has_more: result.has_more,
        items: [...this.healthLogPagination.items, ...result.items],
      };
    } finally {
      runInAction(() => {
        this.isLoadingNextPage = false;
      });
    }
  }

  dispose = () => {
    this._disposers.forEach((d) => d());
  };

  @observable isInitializing = true;
  private async init() {
    try {
      const hasAnyStudentVisibleHealthLogRecords =
        await api.healthLog.hasAnyStudentVisibleHealthLogRecords();
      if (hasAnyStudentVisibleHealthLogRecords) {
        runInAction(() => {
          this.hasAnyStudentVisibleHealthLogRecords = true;
        });
        this._disposers.push(
          reaction(() => this.selectedStudent?.id, this.reloadHealthLoad, {
            fireImmediately: true,
          })
        );
      }
    } finally {
      runInAction(() => {
        this.isInitializing = false;
      });
    }
  }
}

const ctx = React.createContext<ParticipantsPageVM | null>(null);

export const ParticipantsPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { customerStore, routerStore } = useStores();
  const vm = useMemo(
    () => new ParticipantsPageVM(customerStore, routerStore),
    [customerStore, routerStore]
  );
  useEffect(() => () => vm.dispose(), [vm]);
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useParticipantsPageVM = () => useVM(ctx);
