import {
  Button,
  CompactSegmentedControl,
  CreditIcon,
  ExpandableRow,
  FlipCard,
  formatPrice,
  Label,
  Loader,
  modifiersToIntervals,
  PriceWithCents,
  roundToCents,
  Splash,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";

import {
  CreditsAndPackagesPageVMProvider,
  useCreditsAndPackagesPageVM,
} from "./CreditsAndPackagesPageVm";
import useStores from "../../hooks/useStores";
import { Link, Redirect } from "react-router-dom";
import { ROUTES } from "../../stores/RouterStore";
import { ICreditTypeDTO } from "../../services/api";
import {
  calculatePricePerItem,
  findPriceModifier,
} from "@sizdevteam1/funjoiner-uikit/util/formatPrice";
import cn from "classnames";
import { CreditPackageTile } from "@sizdevteam1/funjoiner-uikit/components/CreditPackageTile/CreditPackageTile";
import {
  ICalculatedOrderDTO,
  ICreditOrderItemDTO,
} from "../../services/api/orders";
import InfoMessage from "src/components/InfoMessage/InfoMessage";

const CreditsAndPackagesPage = observer(() => {
  const { funboxStore } = useStores();
  if (funboxStore.isCreditTypesLoading) {
    return <Splash pathToAnimation={"/splash.json"} />;
  }
  if (funboxStore.selectedFunbox == null) {
    return <Redirect to={ROUTES.ROOT} />;
  }
  return (
    <CreditsAndPackagesPageVMProvider>
      <CreditsAndPackagesPageImpl />
    </CreditsAndPackagesPageVMProvider>
  );
});

const CreditsAndPackagesPageImpl = observer(() => {
  const vm = useCreditsAndPackagesPageVM();
  const { commonStore } = useStores();

  return (
    <div>
      <PageRoot className="!min-h-[calc(100dvh-238px)]">
        <PageHeader showBackLink>Credits & Packages</PageHeader>
        {vm.availableCreditTypes.length > 0 ? (
          <div className="flex flex-col">
            <div className="typography-main text-gray-text-color">
              For Scheduling Present/Future Reservations:
            </div>
            <CompactSegmentedControl
              className={"mt-4"}
              options={vm.tabOptions}
              selected={vm.selectedTab.value}
              onSelect={vm.selectedTab.set}
            />
            {vm.selectedTab.value === "CREDITS" ? (
              <CreditsSection />
            ) : (
              <PackagesSection />
            )}
          </div>
        ) : (
          <InfoMessage className="py-8 px-12">
            {commonStore.companyProfile.name} has not yet <br></br>added any
            Credits <br></br>
            Please <Link to={ROUTES.HELP}>contact</Link> them for new info.
          </InfoMessage>
        )}
      </PageRoot>
      {!vm.calculateOrderVm.isNone && (
        <div className="sticky bottom-0 mx-auto border-0  border-t-[1px] border-solid border-separator-color bg-on-main-color py-3">
          <div className="mx-auto w-[366px]">
            {vm.calculateOrderVm.resultOrPrevious != null ? (
              <PurchaseSummary
                order={vm.calculateOrderVm.resultOrPrevious}
                isLoading={vm.calculateOrderVm.isLoading}
              />
            ) : (
              <Loader />
            )}
          </div>
          <Button
            autoLoading
            onClick={vm.toCheckout}
            className="mx-auto mt-4 w-full max-w-[366px]"
            size="big"
          >
            Proceed To Checkout
          </Button>
        </div>
      )}
    </div>
  );
});

const PurchaseSummary = observer(
  ({
    order,
    isLoading,
  }: {
    order: ICalculatedOrderDTO;
    isLoading: boolean;
  }) => {
    return (
      <div className={"relative"}>
        <div className={cn("flex flex-col", isLoading && "opacity-60")}>
          <Label>Purchase</Label>
          <div className={"max-h-[25vh] overflow-auto overscroll-y-contain "}>
            <div className="grid grid-cols-[minmax(0,1fr)_auto_auto] gap-2 overflow-hidden">
              {order.items.map((item) => (
                <CreditOrderItem orderItem={item as ICreditOrderItemDTO} />
              ))}
            </div>
          </div>
          {order.items.reduce((sum, item) => item.quantity + sum, 0) > 1 && (
            <div className={"mt-4"}>
              <div className="mb-4 h-[1px] bg-separator-color"></div>
              <div className="typography-main_sb flex justify-between text-text-color">
                <div>Total</div>
                <PriceWithCents
                  typography="typography-main_sb"
                  prefix="$"
                  amount={order.final_price_without_fees}
                />
              </div>
            </div>
          )}
        </div>
        {isLoading && <Loader className={"absolute top-0 left-0"} />}
      </div>
    );
  }
);

const CreditOrderItem = observer(
  ({ orderItem }: { orderItem: ICreditOrderItemDTO }) => {
    const creditType = orderItem.credit_type;
    return (
      <PurchaseGridRow
        quantity={orderItem.quantity}
        price={roundToCents(orderItem.final_price / orderItem.quantity)}
        renderItem={() => (
          <div className={"flex flex-row items-center gap-[6px]"}>
            <CreditIcon
              isProgram={creditType.is_program_credit}
              isFree={creditType.is_free}
              size={24}
            />
            <div className="typography-h4 flex-shrink overflow-hidden text-ellipsis whitespace-nowrap text-text-color">
              {creditType.name}
            </div>
            {orderItem.is_package && (
              <div className={"typography-small text-gray-text-color"}>
                Package
              </div>
            )}
          </div>
        )}
      />
    );
  }
);

const PurchaseGridRow = observer(
  ({
    renderItem,
    quantity,
    price,
  }: {
    renderItem: () => React.ReactNode;
    quantity: number;
    price: number;
  }) => {
    return (
      <>
        {renderItem()}
        <div
          className={
            "typography-label mt-[1px] flex flex-row items-center justify-end text-gray-text-color"
          }
        >
          {quantity}&nbsp;<span className={"text-placeholder-color"}>×</span>
        </div>
        <div className={"flex items-center justify-center"}>
          <PriceWithCents amount={price} />
        </div>
      </>
    );
  }
);
const PackagesSection = observer(() => {
  const vm = useCreditsAndPackagesPageVM().packagesSectionVm;
  return (
    <div className={"mt-4 mb-6 flex flex-col gap-4"}>
      <div className={"typography-main text-gray-text-color"}>
        Discounts Predetermined by the Package
      </div>

      {vm.availableProgramCreditTypes.length > 0 && (
        <div className={"flex flex-col gap-4"}>
          <div className={"typography-h2 text-header-color"}>Programs</div>
          <div>
            {vm.availableProgramCreditTypes.map((ct) => (
              <ExpandablePackagesTile creditType={ct} />
            ))}
          </div>
        </div>
      )}
      {vm.availableSessionCreditTypes.length > 0 && (
        <div className={"flex flex-col gap-4"}>
          <div className={"typography-h2 text-header-color"}>Sessions</div>
          <div>
            {vm.availableSessionCreditTypes.map((ct) => (
              <ExpandablePackagesTile creditType={ct} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

const CreditsSection = observer(() => {
  const vm = useCreditsAndPackagesPageVM().creditsSectionVm;
  return (
    <div className="mt-4 mb-6 flex flex-col gap-4">
      <div className={"typography-main text-gray-text-color"}>
        Discounts Auto-Apply by Custom Amount
      </div>
      <div className={"flex flex-col gap-6"}>
        {vm.availableProgramCreditTypes.length > 0 && (
          <div>
            <div className="typography-h2 text-header-color">Programs</div>
            <div className="mt-4 flex flex-col gap-5">
              {vm.availableProgramCreditTypes.map((creditType) => (
                <CreditTypeTile key={creditType.id} creditType={creditType} />
              ))}
            </div>
          </div>
        )}
        {vm.availableSessionCreditTypes.length > 0 && (
          <div>
            <div className="typography-h2 text-header-color">Sessions</div>
            <div className="mt-4 flex flex-col gap-5">
              {vm.availableSessionCreditTypes.map((creditType) => (
                <CreditTypeTile key={creditType.id} creditType={creditType} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const ExpandablePackagesTile = observer(
  ({ creditType }: { creditType: ICreditTypeDTO }) => {
    const vm = useCreditsAndPackagesPageVM().packagesSectionVm;
    const [isOpen, setIsOpen] = useState(true);
    return (
      <ExpandableRow
        setIsOpen={setIsOpen}
        className={"w-full gap-2"}
        expand={
          <div className={"flex flex-col gap-2"}>
            {creditType.packages.map((creditPackage) => (
              <CreditPackageTile
                counter={{
                  value: vm.quantityOf(creditType.id, creditPackage),
                  onIncrement: () =>
                    vm.addPackage(creditType.id, creditPackage),
                  onDecrement: () =>
                    vm.removePackage(creditType.id, creditPackage),
                }}
                creditType={creditType}
                package={creditPackage}
              />
            ))}
          </div>
        }
        isOpen={isOpen}
      >
        <div
          className={
            "cursor-pointer rounded-[10px] bg-on-main-color  shadow-big"
          }
        >
          <div className={"flex flex-row gap-[6px] px-4   py-3 "}>
            <CreditIcon
              isProgram={creditType.is_program_credit}
              isFree={creditType.is_free}
              size={24}
            />
            <div className={"typography-h3 flex-1 text-header-color"}>
              {creditType.name}
            </div>
            <i
              className={cn(
                "icon chevron-down-icon icon-lg transition-all",
                isOpen && "rotate-180"
              )}
            />
          </div>
          {creditType.description && (
            <div
              className={cn(
                "typography-small__t rounded-b-[10px] px-4 py-3 text-gray-text-color transition-all",
                isOpen ? "bg-highlight-color" : "bg-table-row-color"
              )}
            >
              {creditType.description}
            </div>
          )}
        </div>
      </ExpandableRow>
    );
  }
);

const CreditTypeTile = observer(
  ({ creditType }: { creditType: ICreditTypeDTO }) => {
    const vm = useCreditsAndPackagesPageVM().creditsSectionVm;
    const [isFlipped, setIsFlipped] = useState(false);

    const quantity = vm.quantityOf(creditType.id);
    const pricePerItem = calculatePricePerItem(
      creditType.price,
      findPriceModifier(creditType.modifiers, quantity)
    );
    return (
      <FlipCard
        isFlipped={isFlipped}
        front={
          <div className="flex flex-col  overflow-hidden rounded-[10px]">
            <div className="flex flex-col gap-[6px] bg-on-main-color  py-3 px-4">
              <div className="flex  items-start justify-between gap-2">
                <div className="flex flex-col gap-[6px]">
                  <div className="flex gap-[6px]">
                    <CreditIcon
                      size={24}
                      isFree={creditType.is_free}
                      isProgram={creditType.is_program_credit}
                    />
                    <div className="typography-h3 max-w-[210px] text-header-color">
                      {creditType.name}
                    </div>
                  </div>
                  <div className="typography-small  flex items-center">
                    <div className="flex items-center gap-[6px]">
                      <PriceWithCents
                        className="text-header-color"
                        typography="typography-label"
                        prefix="$"
                        amount={pricePerItem}
                      />
                      {pricePerItem !== creditType.price && (
                        <PriceWithCents
                          className="text-placeholder-color line-through decoration-gray-text-color"
                          typography="typography-small"
                          prefix="$"
                          amount={creditType.price}
                        />
                      )}
                    </div>

                    {creditType.modifiers.length > 0 && (
                      <div className="flex">
                        <div className="mx-[10px] h-4 w-[1px] bg-separator-color"></div>
                        <Button
                          className="!typography-label h-4"
                          onClick={() => setIsFlipped(true)}
                          kind="text"
                        >
                          View Discounts
                          <i className="icon forward-icon ml-[4px] !mr-0" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {quantity === 0 ? (
                  <Button
                    color={creditType.is_program_credit ? "main" : "darkOrange"}
                    className={`h-10`}
                    onClick={() => vm.addCreditType(creditType.id)}
                    kind="outlined"
                  >
                    Add
                  </Button>
                ) : (
                  <div className="flex h-[40px] items-center gap-2">
                    <Button
                      color={
                        creditType.is_program_credit ? "main" : "darkOrange"
                      }
                      onClick={() => vm.removeCreditType(creditType.id)}
                      kind="icon"
                    >
                      <i className={"icon partial-icon"} />
                    </Button>
                    <div
                      className={classNames(
                        "typography-h3 min-w-[12px] text-center",
                        creditType.is_program_credit
                          ? "text-main-color"
                          : "text-surface-orange-dark-color"
                      )}
                    >
                      {quantity}
                    </div>

                    <Button
                      color={
                        creditType.is_program_credit ? "main" : "darkOrange"
                      }
                      onClick={() => vm.addCreditType(creditType.id)}
                      kind="icon"
                    >
                      <i className={"icon add-icon"} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {creditType.description && (
              <div className="typography-small__t  bg-table-row-color px-4 py-3 text-gray-text-color">
                {creditType.description}
              </div>
            )}
          </div>
        }
        renderBack={(style) => (
          <div
            style={style}
            className="flex h-full flex-col justify-between gap-1 rounded-[10px] bg-on-main-color px-4 py-3"
          >
            <div className="flex flex-col gap-[6px]">
              {modifiersToIntervals(creditType.modifiers).map(
                (modifier, index) => {
                  let intervalText: string;
                  if (modifier.min_quantity === modifier.max_quantity) {
                    intervalText = `${modifier.min_quantity}`;
                  } else if (modifier.max_quantity == null) {
                    intervalText = `${modifier.min_quantity}+`;
                  } else {
                    intervalText = `${modifier.min_quantity}-${modifier.max_quantity}`;
                  }
                  return (
                    <div key={index} className="flex justify-between">
                      <div className="typography-main text-text-color">
                        {intervalText}
                        &nbsp;Credits
                      </div>
                      <div className="typography-main_sb flex items-center gap-1 text-right text-icon-dark-green-color">
                        <div className="typography-main text-gray-text-color">
                          Save{" "}
                        </div>
                        {modifier.discount_percent != null ? (
                          `${modifier.discount_percent}%`
                        ) : (
                          <div className="flex gap-1">
                            {formatPrice(modifier.discount_flat_amount!, {
                              prefix: "$",
                            })}
                            <div className="typography-main text-gray-text-color">
                              per Credit
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <Button
              onClick={() => setIsFlipped(false)}
              className="!typography-label mx-auto"
              kind="text"
            >
              <i className="icon back-icon" />
              Back
            </Button>
          </div>
        )}
      />
    );
  }
);

export default CreditsAndPackagesPage;
