import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import api, { ICreditTypeDTO, IOrderDTO } from "src/services/api";
import {
  CommonStore,
  CustomerStore,
  PaymentStore,
  RouterStore,
} from "src/stores";
import { ROUTES } from "src/stores/RouterStore";
import { Cents } from "@sizdevteam1/funjoiner-uikit/types";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { ICancelablePromise } from "@sizdevteam1/funjoiner-web-shared/services";
import { isAbortError } from "@sizdevteam1/funjoiner-uikit";
import * as Sentry from "@sentry/react";
import { BaseOrderCalculatorVm } from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/BaseOrderCalculatorVm";
import {
  ICalculatedOrderDTO,
  ICalculateOrderDTO,
} from "../../services/api/orders";

export type ConcreteUpgradeOption = {
  to: ICreditTypeDTO;
  price: Cents;
};

export class UpgradeCreditPageVM {
  constructor(
    private fromCreditTypeId: number,
    private commonStore: CommonStore,
    private customerStore: CustomerStore,
    private paymentStore: PaymentStore,
    private routerStore: RouterStore
  ) {
    makeObservable(this);
  }

  @observable
  private _quantity: number = 1;

  public get quantity(): number {
    return this._quantity;
  }

  @computed
  get totalUpgradePrice(): number | undefined {
    return this.selectedUpgrade != null
      ? this.selectedUpgrade.price * this.quantity
      : undefined;
  }

  @computed
  get canDecrement() {
    return this._quantity > 1;
  }

  @action.bound
  decrement() {
    if (this.canDecrement) {
      this._quantity--;
      this._orderCalculatorVm.recalculate();
    }
  }

  @computed
  get canIncrement() {
    return (
      this._quantity <
      this.customerStore.credits.filter(
        (e) => e.credit_type_id === this.fromCreditTypeId && e.can_be_upgraded
      ).length
    );
  }

  @action.bound
  increment() {
    if (this.canIncrement) {
      this._quantity++;
      this._orderCalculatorVm.recalculate();
    }
  }

  @observable
  selectedUpgrade?: ConcreteUpgradeOption;

  @action.bound
  async selectUpgradeOption(o: ConcreteUpgradeOption) {
    this.selectedUpgrade = o;
    await this._orderCalculatorVm.recalculate();
  }

  @computed
  get upgradeOptions(): ConcreteUpgradeOption[] {
    return this.fromCreditType.upgrade_options.map((e) => {
      return {
        to: this.commonStore.creditTypesById[e.to_credit_type_id],
        price: e.price,
      };
    });
  }

  private _orderCalculatorVm = new OrderCalculatorVm(
    computed(() => {
      const creditsToUpgrade = this.customerStore.credits
        .filter(
          (e) => e.credit_type_id === this.fromCreditTypeId && e.can_be_upgraded
        )
        .slice(0, this.quantity);
      const selectedUpgrade = this.selectedUpgrade;

      if (creditsToUpgrade.length === 0 || selectedUpgrade == null) {
        return null;
      }

      return {
        items: creditsToUpgrade.map((e) => ({
          credit_id: e.id,
          to_credit_type_id: selectedUpgrade.to.id,
        })),
        promocode_id: undefined,
      };
    }),
    api.orders.calculate
  );
  private _prevSearchPromise?: ICancelablePromise<IOrderDTO>;

  @computed get order() {
    return this._orderCalculatorVm.calculationResult;
  }

  @computed
  get isLoading() {
    return this._orderCalculatorVm.isLoading;
  }

  @action.bound
  async proceedToPayment() {
    const payload = this._orderCalculatorVm.orderPayload.get();
    if (this.order == null || payload == null) return;
    this.paymentStore.setIncompleteOrder({
      type: "order",
      order: this.order,
      payload: payload,
    });
    this.routerStore.navigate(`${ROUTES.CHECKOUT}?step=payment`);
  }

  @action.bound async placeComplimentaryUpgradeOrder() {
    const payload = this._orderCalculatorVm.orderPayload.get();
    if (this.order == null || payload == null || this.order.final_price !== 0)
      return;
    try {
      await api.orders.place({
        ...payload,
        payment_type: "stripe",
      });
      await this.customerStore.loadCredits();
      notificator.success("Successful Upgrade!", "You are ready to Schedule");
      this.routerStore.navigate(ROUTES.DASHBOARD);
    } catch (e) {
      notificator.error("Error", e);
    }
  }

  @computed
  get fromCreditType(): ICreditTypeDTO {
    return this.commonStore.creditTypesById[this.fromCreditTypeId];
  }
}

const ctx = React.createContext<UpgradeCreditPageVM | null>(null);

export const UpgradeCreditPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { customerStore, commonStore, paymentStore, routerStore } = useStores();
  const { id } = useParams<{ id: string }>();
  const vm = useMemo(
    () =>
      new UpgradeCreditPageVM(
        parseInt(id),
        commonStore,
        customerStore,
        paymentStore,
        routerStore
      ),
    [id, customerStore, commonStore, paymentStore, routerStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useUpgradeCreditPageVM = () => useVM(ctx);

class OrderCalculatorVm extends BaseOrderCalculatorVm<
  ICalculateOrderDTO,
  ICalculatedOrderDTO
> {
  recalculate = async (): Promise<void> => {
    try {
      await super.recalculate();
    } catch (e) {
      if (isAbortError(e)) return;
      notificator.error("Error!", e);
    }
  };
}
