import React, { useEffect, useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import {
  action,
  computed,
  IComputedValue,
  makeObservable,
  observable,
  reaction,
  when,
} from "mobx";
import { ROUTES } from "src/stores/RouterStore";
import { AuthStore, PaymentStore, RouterStore } from "src/stores";
import api, {
  ICreateCreditOrderItemDTO,
  ICreditTypeDTO,
} from "src/services/api";
import FunboxStore from "../../stores/FunboxStore";

import { computedFn } from "mobx-utils";
import * as Sentry from "@sentry/react";
import { observables } from "@sizdevteam1/funjoiner-web-shared/observables/observables";
import { BaseOrderCalculatorVm } from "@sizdevteam1/funjoiner-web-shared/components/ScheduleAndPay/BaseOrderCalculatorVm";
import {
  ICalculatedOrderDTO,
  ICalculateOrderDTO,
  ICreditOrderItemDTO,
} from "../../services/api/orders";
import {
  BuyCreditsAndSaveTab,
  CreditPackageDTO,
} from "../../services/api/common";
import formField from "../../models/formField";
import { exists } from "@sizdevteam1/funjoiner-uikit";
import { ICancelablePromise } from "@sizdevteam1/funjoiner-web-shared/services";

export class CreditsAndPackagesPageVM {
  constructor(
    private funboxStore: FunboxStore,
    private paymentStore: PaymentStore,
    private routerStore: RouterStore,
    private authStore: AuthStore
  ) {
    this.calculateOrderVm = new BuyCreditsAndSaveCalculateOrderVm(
      computed(() => this.orderPayload),
      (payload) => api.orders.calculate(payload)
    );

    this.disposers.push(
      reaction(
        () => this.orderPayload,
        () => this.calculateOrderVm.recalculate(),
        { fireImmediately: this.orderPayload != null }
      )
    );

    if (this.routerStore.searchParams.action === "proceed") {
      this.toCheckout();
    }
    makeObservable(this);
  }

  calculateOrderVm;

  creditsSectionVm = new CreditsSectionVm(
    computed(() => this.availableCreditTypes),
    this.routerStore
  );

  packagesSectionVm = new PackagesSectionVm(
    computed(() => this.availableCreditTypes),
    this.routerStore
  );

  @observable selectedTab = formField<BuyCreditsAndSaveTab>(
    this.tabOptions[0].key
  );

  @computed
  get tabOptions() {
    const selectedFunbox = this.funboxStore.selectedFunbox!;
    const creditsTabOption = selectedFunbox.buy_credits_and_save_credits_tab
      .is_enabled
      ? {
          key: "CREDITS" as const,
          label: selectedFunbox.buy_credits_and_save_credits_tab.name,
        }
      : null;
    const packagesTabOption =
      selectedFunbox.buy_credits_and_save_packages_tab.is_enabled &&
      this.packagesSectionVm.hasPackages
        ? {
            key: "PACKAGES" as const,
            label: selectedFunbox.buy_credits_and_save_packages_tab.name,
          }
        : null;

    let tabs =
      selectedFunbox.buy_credits_and_save_first_tab === "CREDITS"
        ? [creditsTabOption, packagesTabOption].filter(exists)
        : [packagesTabOption, creditsTabOption].filter(exists);

    if (tabs.length === 0) {
      try {
        throw new Error("No tabs available");
      } catch (e) {
        Sentry.captureException(e);
        tabs = [
          {
            key: "CREDITS" as const,
            label: "Credits",
          },
        ];
      }
    }

    return tabs;
  }

  @computed get availableCreditTypes() {
    return this.funboxStore.creditTypes.filter(
      (ct) => !ct.is_hidden_from_flexible_payments_page
    );
  }

  @computed get orderPayload() {
    const creditOrderItems = Object.values(
      this.creditsSectionVm.items.get()
    ).map((item) => ({
      ...item,
      is_package: false,
    }));

    const packageOrderItems = this.packagesSectionVm.items
      .get()
      .map((item) => ({
        ...item,
        is_package: true,
      }));

    const payload = {
      items: [...creditOrderItems, ...packageOrderItems],
      promocode_id: undefined,
    };

    if (payload.items.length === 0) return null;
    return payload;
  }

  @action.bound async toCheckout() {
    if (!this.authStore.loggedIn) {
      const creditItemsRaw =
        this.routerStore.searchParams[
          CreditsSectionVm.CREDIT_ITEMS_SEARCH_PARAM
        ];
      const packageItemsRaw =
        this.routerStore.searchParams[
          PackagesSectionVm.CREDIT_PACKAGES_ITEMS_SEARCH_PARAM
        ];
      const callbackUrl = `${ROUTES.FLEXIBLE_PAYMENTS}?${CreditsSectionVm.CREDIT_ITEMS_SEARCH_PARAM}=${creditItemsRaw}&${PackagesSectionVm.CREDIT_PACKAGES_ITEMS_SEARCH_PARAM}=${packageItemsRaw}&action=proceed`;
      this.routerStore.navigate(ROUTES.SIGN_IN, {
        from: callbackUrl,
      });
      return;
    }
    await when(() => !this.calculateOrderVm.isLoading);
    const order = this.calculateOrderVm.calculationResult;
    if (order == null) {
      return;
    }

    this.paymentStore.setIncompleteOrder({
      type: "order",
      order: order,
      payload: this.orderPayload!,
    });

    this.routerStore.setSearchParam("action", undefined, true);
    this.routerStore.navigate(ROUTES.CHECKOUT);
  }

  disposers: (() => void)[] = [];
  dispose = () => {
    this.disposers.forEach((d) => d());
  };
}

class CreditsSectionVm {
  static CREDIT_ITEMS_SEARCH_PARAM = "selected_credit_types";
  constructor(
    private availableCreditTypes: IComputedValue<ICreditTypeDTO[]>,
    private routerStore: RouterStore
  ) {
    makeObservable(this);
  }

  @computed get availableProgramCreditTypes() {
    return this.availableCreditTypes.get().filter((ct) => ct.is_program_credit);
  }

  @computed get availableSessionCreditTypes() {
    return this.availableCreditTypes
      .get()
      .filter((ct) => !ct.is_program_credit);
  }

  quantityOf = computedFn((creditTypeId: number) => {
    return this.items.get()[creditTypeId]?.quantity ?? 0;
  });

  @observable items = observables.param<{
    [creditTypeId: number]: {
      credit_type_id: number;
      quantity: number;
    };
  }>(this.routerStore, {
    type: "object",
    name: CreditsSectionVm.CREDIT_ITEMS_SEARCH_PARAM,
    fallback: computed(() => ({})),
  });

  addCreditType = (creditTypeId: number) => {
    const items = { ...this.items.get() };
    if (items[creditTypeId] == null) {
      items[creditTypeId] = {
        credit_type_id: creditTypeId,
        quantity: 0,
      };
    }

    items[creditTypeId].quantity += 1;
    this.items.set(items);
  };

  removeCreditType = (creditTypeId: number) => {
    const items = { ...this.items.get() };
    if (items[creditTypeId] == null) {
      return;
    }

    items[creditTypeId].quantity -= 1;
    if (items[creditTypeId].quantity === 0) {
      delete items[creditTypeId];
    }
    this.items.set(items);
  };
}

class PackagesSectionVm {
  static CREDIT_PACKAGES_ITEMS_SEARCH_PARAM = "selected_packages";
  constructor(
    private availableCreditTypes: IComputedValue<ICreditTypeDTO[]>,
    private routerStore: RouterStore
  ) {
    makeObservable(this);
  }

  @computed
  private get _creditTypesWithPackages() {
    return this.availableCreditTypes
      .get()
      .filter((ct) => ct.packages.length > 0);
  }

  @computed
  get hasPackages() {
    return this._creditTypesWithPackages.length > 0;
  }

  @computed
  get availableProgramCreditTypes() {
    return this._creditTypesWithPackages.filter((ct) => ct.is_program_credit);
  }

  @computed
  get availableSessionCreditTypes() {
    return this._creditTypesWithPackages.filter((ct) => !ct.is_program_credit);
  }

  @observable
  items = observables.param<
    {
      credit_type_id: number;
      quantity: number;
    }[]
  >(this.routerStore, {
    type: "object",
    name: PackagesSectionVm.CREDIT_PACKAGES_ITEMS_SEARCH_PARAM,
    fallback: computed(() => []),
  });

  quantityOf = computedFn(
    (creditTypeId: number, creditPackage: CreditPackageDTO) => {
      return this.items
        .get()
        .filter(
          (item) =>
            item.credit_type_id === creditTypeId &&
            item.quantity === creditPackage.number_of_credits
        ).length;
    }
  );

  addPackage = (creditTypeId: number, creditPackage: CreditPackageDTO) => {
    const items = [...this.items.get()];
    items.push({
      credit_type_id: creditTypeId,
      quantity: creditPackage.number_of_credits,
    });
    this.items.set(items);
  };

  removePackage = (creditTypeId: number, creditPackage: CreditPackageDTO) => {
    const items = [...this.items.get()];
    const existingItemIndex = items.findIndex(
      (item) =>
        item.credit_type_id === creditTypeId &&
        item.quantity === creditPackage.number_of_credits
    );
    if (existingItemIndex !== -1) {
      items.splice(existingItemIndex, 1);
    }
    this.items.set(items);
  };
}

const ctx = React.createContext<CreditsAndPackagesPageVM | null>(null);

export const CreditsAndPackagesPageVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { funboxStore, paymentStore, routerStore, authStore } = useStores();

  const vm = useMemo(
    () =>
      new CreditsAndPackagesPageVM(
        funboxStore,
        paymentStore,
        routerStore,
        authStore
      ),
    [funboxStore, paymentStore, routerStore, authStore]
  );
  useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

class BuyCreditsAndSaveCalculateOrderVm extends BaseOrderCalculatorVm<
  ICalculateOrderDTO,
  ICalculatedOrderDTO
> {
  constructor(
    orderPayload: IComputedValue<ICalculateOrderDTO | null>,
    calculateOrder: (
      payload: ICalculateOrderDTO
    ) => ICancelablePromise<ICalculatedOrderDTO>,
    onRecalculate?: () => void
  ) {
    super(orderPayload, calculateOrder, onRecalculate);
    this.setValue({ type: "result", result: undefined });
  }

  @computed
  get isNone() {
    return this.value.type === "result" && this.value.result == null;
  }
}

export const useCreditsAndPackagesPageVM = () => useVM(ctx);
