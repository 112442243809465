import React from "react";
import ReactCodeInput from "src/lib/ReactVerificationCodeInput/";
import cn from "classnames";
import styles from "./styles.module.scss";

interface IProps {
  type?: "text" | "number";
  onChange?: (val: string) => void;
  onComplete?: (val: string) => void;
  fields?: number;
  loading?: boolean;
  title?: string;
  fieldWidth?: number;
  fieldHeight?: number;
  autoFocus?: boolean;
  className?: string;
  values: string[];
  disabled?: boolean;
  required?: boolean;
  placeholder?: string[];
}

const CodeInput: React.FunctionComponent<IProps> = ({ className, ...rest }) => (
  <ReactCodeInput
    key={rest.values.length === 0 ? "key" : undefined} // hack to reset input
    className={cn(styles.CodeInput, className)}
    autoFocus
    {...rest}
  />
);

export default CodeInput;
