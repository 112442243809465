import React from "react";
import PageRoot from "src/components/PageRoot";
import SizedBox from "src/components/SizedBox";
import styles from "./PaymentSuccessPage.module.scss";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import useStores from "../../../hooks/useStores";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../../stores/RouterStore";
import ReceiptSection from "./ReceiptSection";
import { observer } from "mobx-react-lite";
import { IOrderDTO } from "src/services/api";
import DownloadReceiptButton from "./components/DownloadReceiptButton";

const PaymentSuccessPage: React.FC = () => {
  const { paymentStore, routerStore } = useStores();
  const completedOrder = paymentStore.completedOrder;

  return completedOrder == null ? (
    <Redirect to={ROUTES.ROOT} />
  ) : (
    <PageRoot>
      <ReceiptSection
        order={completedOrder.order}
        paymentOption={{
          type: "card",
          card: completedOrder.card,
        }}
        title="Payment Successful!"
      />
      <DownloadReceiptButton order={completedOrder.order} />
      <SizedBox height={20} />
      <div className={styles.next}>
        <div className={"text-h2"}>What’s Next?</div>
        <div className={"text-main"}>
          Thank you. Now you can schedule with your credits
        </div>
        <ScheduleDatesButton order={completedOrder.order} />
        <Button
          size={"big"}
          kind={"text"}
          onClick={() => routerStore.navigate(ROUTES.DASHBOARD)}
        >
          Schedule Later
        </Button>
      </div>
    </PageRoot>
  );
};

interface IScheduleDatesButtonProps {
  order: IOrderDTO;
}

const ScheduleDatesButton: React.FC<IScheduleDatesButtonProps> = observer(
  ({ order }) => {
    const { routerStore } = useStores();

    const orderItem = order.items.find(
      (e) => e.type === "credit_item" || e.type === "upgrade_item"
    );

    const creditTypeId = ((): number | null => {
      switch (orderItem?.type) {
        case "credit_item":
          return orderItem.credit_type.id;
        case "upgrade_item":
          return orderItem.to_credit_type_id;
        case undefined:
          return null;
      }
    })();

    return creditTypeId != null ? (
      <Button
        size={"big"}
        onClick={() => {
          routerStore.navigate(
            (ROUTES.SCHEDULE +
              `?schedule_credit_type_id=${creditTypeId}`) as any
          );
        }}
      >
        Schedule Dates
      </Button>
    ) : null;
  }
);

export default PaymentSuccessPage;
