import * as Sentry from "@sentry/react";
import React, { useMemo, useEffect } from "react";
import { action, computed, makeObservable, reaction } from "mobx";
import useStores from "../../hooks/useStores";
import useVM from "../../hooks/useVM";
import { CustomerStore, RouterStore } from "../../stores";
import { applicationsAndWaitlistsPageRoute } from "./ApplicationsAndWaitlistsPage";
import { ROUTES } from "../../stores/RouterStore";
import { applicationPageRoute } from "../ApplicationPage/ApplicationPage";
import notificator from "../../services/systemNotifications/notificationCenterService";

export class ApplicationsAndWaitlistsPageVm {
  constructor(
    private customerStore: CustomerStore,
    private routerStore: RouterStore
  ) {
    makeObservable(this);

    this._disposers.push(
      reaction(
        () => this._studentIdsWithApplicationsOrWaitlists,
        (value) => {
          if (value.size === 0) {
            this.routerStore.navigate(ROUTES.DASHBOARD, { replace: true });
          } else if (!value.has(this.selectedStudentId)) {
            this.selectStudent(value.values().next().value);
          }
        },
        { fireImmediately: true }
      )
    );
  }
  @computed
  get selectedStudentId() {
    const studentId = applicationsAndWaitlistsPageRoute.getParams(
      this.routerStore
    ).searchParams?.studentId;

    return studentId != null ? Number(studentId) : this.students[0]?.id;
  }

  @action
  selectStudent = (studentId: number) => {
    applicationsAndWaitlistsPageRoute.setSearchParams(this.routerStore, {
      studentId: studentId.toString(),
    });
  };
  @computed
  get students() {
    return this.customerStore.studentsWithCustomerAsParticipant.filter(
      (student) => this._studentIdsWithApplicationsOrWaitlists.has(student.id)
    );
  }

  @computed
  private get _studentIdsWithApplicationsOrWaitlists() {
    return new Set([
      ...this.customerStore.applications.map((a) => a.student.id),
      ...this.customerStore.waitlistRecords.map((w) => w.student_id),
    ]);
  }

  @computed
  get studentApplications() {
    return this.customerStore.applications.filter(
      (a) => a.student.id === this.selectedStudentId
    );
  }

  @computed
  get studentWaitlistRecords() {
    return this.customerStore.waitlistRecords.filter(
      (w) => w.student_id === this.selectedStudentId
    );
  }

  navigateToApplicationPage = (applicationId: string) => {
    this.routerStore.navigateToRoute(
      applicationPageRoute.build({
        pathParams: {
          application_id: applicationId,
        },
      })
    );
  };

  @action
  leaveWaitlist = async (
    programId: string,
    studentId: number,
    scheduleSetId: string
  ) => {
    try {
      await this.customerStore.leaveWaitlist(
        programId,
        studentId,
        scheduleSetId
      );
    } catch (e) {
      notificator.error("Error!", e);
      Sentry.captureException(e);
    }
  };

  private _disposers = Array<() => void>();

  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}

const ctx = React.createContext<ApplicationsAndWaitlistsPageVm | null>(null);

export const ApplicationsAndWaitlistsPageVmProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { customerStore, routerStore } = useStores();
  const vm = useMemo(
    () => new ApplicationsAndWaitlistsPageVm(customerStore, routerStore),
    []
  );
  useEffect(() => () => vm.dispose(), [vm]);
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useApplicationsAndWaitlistsPageVm = () => useVM(ctx);
