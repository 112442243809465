import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { IPromocodeDTO } from "src/services/api/profile";
import { CustomerStore, PaymentStore } from "src/stores";
import { Cents } from "@sizdevteam1/funjoiner-uikit/types";

const ctx = React.createContext<AddPromocodeButtonVM | null>(null);

export const AddPromocodeButtonVMProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { paymentStore, customerStore } = useStores();
  const vm = useMemo(
    () => new AddPromocodeButtonVM(paymentStore, customerStore),
    [paymentStore, customerStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useAddPromocodeButtonVM = () => useVM(ctx);

export class AddPromocodeButtonVM {
  @observable
  private _draftPromocode: string = "";
  public get draftPromocode(): string {
    return this._draftPromocode;
  }

  @observable
  promocodeError = "";

  @observable
  isPromocodeInputOpen = Boolean(
    this.paymentStore.incompleteOrder?.promocode_id
  );

  get orderDiscount() {
    return this.paymentStore.incompleteOrder?.discount;
  }

  @action
  setIsPromocodeInputOpen(value: boolean) {
    this.isPromocodeInputOpen = value;
  }

  @action
  setPromocodeError(value: string) {
    this.promocodeError = value;
  }

  public set draftPromocode(value: string) {
    const validStr = value.replace(/[^a-z|^0-9]/gi, "");
    this._draftPromocode = validStr.toUpperCase();
    this.promocodeError = "";
  }

  @computed
  get availablePromocodes(): IPromocodeDTO[] {
    const order_credit_types =
      this.paymentStore.incompleteOrder?.items.map(
        (item) => item.type === "credit_item" && item.credit_type.id
      ) ?? [];

    return this.customerStore.promocodes
      .filter(
        (p) =>
          p.credit_type_ids == null ||
          p.credit_type_ids.some((id) => order_credit_types.includes(id))
      )
      .filter((p) => (this.orderDiscount ? !p.base_price_only : p));
  }

  @computed
  get isPromocodeApplied() {
    return this.appliedPromocode != null;
  }

  @computed
  get appliedPromocode(): { id: string; discount: Cents } | null {
    const order = this.paymentStore.incompleteOrder;
    if (order?.promocode_id == null) {
      return null;
    }

    return {
      id: order.promocode_id,
      discount: order.promocode_discount,
    };
  }

  @computed
  get isApplyDisabled() {
    return (
      this.draftPromocode.length === 0 ||
      this.paymentStore.isModifyingOrder ||
      this.promocodeError !== ""
    );
  }

  @computed
  get isRemoveDisabled() {
    return this.paymentStore.isModifyingOrder;
  }

  constructor(
    private paymentStore: PaymentStore,
    private customerStore: CustomerStore
  ) {
    makeObservable(this);
  }

  @action.bound
  async applyPromocode() {
    try {
      await this.paymentStore.applyPromocode(this.draftPromocode);
      this.draftPromocode = "";
    } catch (e) {
      typeof e === "string"
        ? //Передача тела ошибки,если строка в this.error без пользовательского input.value
          //summerffg23  promocode not found => Promocode not found
          this.setPromocodeError(e.replace(`${this._draftPromocode} p`, "P"))
        : this.setPromocodeError("Unknown Error");
    }
  }

  @action.bound
  async removePromocode() {
    await this.paymentStore.removePromocode();
  }

  @action.bound
  onPromocodeClicked(name: string) {
    this.draftPromocode = name;
  }
}
